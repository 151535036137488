import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class TimePeriodDataTable extends React.Component {
  constructor(props) {
    super(props);

    const initializeAllChecked = this.props.initializeAllChecked;
    const previouslySelectedKeys = this.props.previouslySelectedKeys ? new Set(this.props.previouslySelectedKeys.map(r => r.id)) : new Set();
    const displayAllChecked = initializeAllChecked && previouslySelectedKeys.size == 0;
    const checkHeader = this.props.records.length === previouslySelectedKeys.size;
    this.state = {
      selectedKeys: displayAllChecked ? new Set(this.props.records.map(r => r.id)) : previouslySelectedKeys,
      headerChecked: displayAllChecked || checkHeader
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isCheckable) {
      if (!_.isEqual(this.props.records.map(r => r.id), prevProps.records.map(r => r.id))) {
        const initializeAllChecked = this.props.initializeAllChecked;
        this.setState( {
          selectedKeys: initializeAllChecked ? new Set(this.props.records.map(r => r.id)) : new Set(),
          headerChecked: initializeAllChecked
        });
      }
      if (this.state.selectedKeys !== prevState.selectedKeys
          && typeof this.props.onSelectedRowsChange === 'function') {
        this.props.onSelectedRowsChange(this.state.selectedKeys);
      }
    }
  }

  componentDidMount() {
    if (this.props.initializeAllChecked && typeof this.props.onSelectedRowsChange === 'function') {
      this.props.onSelectedRowsChange(this.state.selectedKeys);
    }
  }

  render() {
    return (
      this.renderFixed()
    );
  }

  renderFixed = () => {
    return (
      <div className="table-light">
        <table className="table">
          <thead>
            <tr>
              {this.renderCheckableHeader()}
              <th className="pull-left" key={this.props.headerName}>All {this.props.headerName}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderFixedRows()}
          </tbody>
        </table>
      </div>
    );
  };

  renderFixedRows = () => {
    if (this.props.isLoading) {
      return (this.renderLoading());
    } else if (this.props.records.length) {
      const TimePeriodDataTableRow = this.props.rowComponent;
      return (
        this.props.records.map((record) =>
          <TimePeriodDataTableRow
            key={record.id}
            record={record}
            selected={record.departmentRequired ? false : this.state.selectedKeys.has(record.id)}
            onSelectedChange={this.handleSelectedRowsChange}
            isDisabled={this.props.isDisabled || record.departmentRequired}
            isRating={this.props.isRating}
            isDepartmentRequired={record.departmentRequired}
          />
        ));
    } else {
      return (this.renderEmpty());
    }
  };

  renderLoading = () => {
    return (
      <tr><td colSpan={this.props.rowComponent.headers().length}>Loading...</td></tr>
    );
  };

  renderEmpty = () => {
    return (
      <tr><td colSpan={this.props.rowComponent.headers().length}>No records available.</td></tr>
    );
  };

  renderCheckableHeader = () => {
    if (!this.props.isCheckable) {
      return null;
    }

    const isDisabled = this.props.isDisabled;
    return (
      <th className='checkbox pull-left'>
        <label className={`checkbox-custom ${this.state.headerChecked ? 'checked' : ''}`}>
          <input
            className='boolean'
            type='checkbox'
            onChange={this.handleSelectAllChange}
            disabled={isDisabled}
            checked={this.state.headerChecked} />
        </label>
      </th>
    );
  };

  handleSelectedRowsChange = (key, checked) => {
    if(checked) {
      this.addSelectedKey(key);
    } else {
      this.removeSelectedKey(key);
    }
  };

  allRowsChecked = () => {
    return this.props.records.length === this.state.selectedKeys.size;
  };

  handleHeaderChangeFromRows = () => {
    this.setState({
      headerChecked: this.allRowsChecked()
    });
  };

  handleSelectAllChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedKeys: new Set(this.props.records.filter(r => !r.departmentRequired).map(r => r.id)),
        headerChecked: true
      });
    } else {
      this.setState({
        selectedKeys: new Set(),
        headerChecked: false
      });
    }
  };

  addSelectedKey = (key) => {
    this.setState(({ selectedKeys }) => ({
      selectedKeys: new Set(selectedKeys.add(key)),
    }),() => this.handleHeaderChangeFromRows());
  };

  removeSelectedKey = (key) => {
    this.setState(({ selectedKeys }) => {
      const copySet = new Set(selectedKeys);
      copySet.delete(key);

      return {
        selectedKeys: copySet
      };
    },() => this.handleHeaderChangeFromRows());
  };
}

TimePeriodDataTable.propTypes = {
  records: PropTypes.array.isRequired,
  rowComponent: PropTypes.func.isRequired,
  previouslySelectedKeys: PropTypes.array,
  isCheckable: PropTypes.bool,
  initializeAllChecked: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  departmentRequired: PropTypes.bool,
  onSelectedRowsChange: PropTypes.func,
  headerName: PropTypes.string.isRequired,
  isRating: PropTypes.bool,
};

export default TimePeriodDataTable;
